import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import config from "../../../../config.json";
import {localStorageGet} from "../../../utils";
import {IRegistration} from "../models/AuthRegistration";

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${config.APP_URL}/api/${config.VERSION_API}/accounts/get-user`
export const LOGIN_URL = `${config.APP_URL}/api/${config.VERSION_API}/auths/login`
export const REGISTER_URL = `${config.APP_URL}/api/${config.VERSION_API}/auths/signup`;
// export const REGISTER_URL = `${API_URL}/auths/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {
    return axios.post(LOGIN_URL, {email, password})
}

// Server should return AuthModel
export function register(data:IRegistration) {
    return axios.post<AuthModel>(REGISTER_URL, data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    // Check common redux folder => setupAxios
    return axios.get(GET_USER_BY_ACCESSTOKEN_URL)
}
