export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export const setupToken = (axios:any ,accessToken:string) =>{
    axios.interceptors.request.use((res:any )=> {
        res.headers.Authorization = `Bearer ${accessToken}`
        return res;
    });
}
