import React, {memo, useEffect, useState} from 'react';
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import {Link, useLocation,useHistory} from "react-router-dom";
import { sendVerifyOAuthApiRequest } from '../../../../api/account';
import {localStorageGet, NotyWrap} from "../../../utils";
import * as auth from "../redux/AuthRedux";
import {useDispatch} from "react-redux";
import {IRegistration} from "../models/AuthRegistration";
import {IRegistrationSoc} from "../models/AuthRegistrationSoc";

interface IProps {
    match: {
        params: {
            type: string;
        }
    } | undefined;
}
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
const RegistrationSuccessRedirect = ({match}: IProps) =>{
    const [typePage, setTypePage] = useState<null| string>(null);
    const query = useQuery();
    let code = query.get("code");
    const dispatch = useDispatch();
    let history = useHistory();
    //
    // Set type page
    //
    useEffect(()=>{
        if(match && match.params.type){
            setTypePage(match.params.type);
        }
    },[match]);

    //
    // Send request on success registration or login
    //
    useEffect(()=>{
        if(typePage && typePage !==null && code  ){
            let newData:IRegistrationSoc = {
                type: typePage,
                code: code,
                state: 'null'
            };
            let utm_source = localStorageGet('utm_source', null);
            let utm_medium = localStorageGet('utm_medium', null);
            let utm_campaign = localStorageGet('utm_campaign', null);
            let utm_term = localStorageGet('utm_term', null);
            // let referral = localStorageGet('referral', false);
            // if (referral) data.append('referral', referral);

            newData.utm_source = utm_source ? utm_source : '';
            newData.utm_medium = utm_medium ? utm_medium : '';
            newData.utm_campaign = utm_campaign ? utm_campaign : '';
            newData.utm_term = utm_term ? utm_term : '';
            sendVerifyOAuthApiRequest(newData)
                .then((res)=>{
                    localStorage.setItem('LETSSTORY_TOKEN', res);
                    dispatch(auth.actions.login(res));
                    history.push("/");
                    // window.location.href = '/';
                })
                .catch(()=>{
                    NotyWrap('Ошибка привязки, повторите позже', 'error');
                })
        }
    },[typePage,code]);

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Logo */}
                <a href='#' className='mb-12'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
                </a>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                {
                    match && code  ?
                        <h1>
                            Загрузка...
                        </h1>
                        :
                        <>
                            <h1>Ошибка, попробуйте еще раз</h1>
                            <Link to="/">Вернуться на главную</Link>
                        </>
                }

                {/* end::Wrapper */}
            </div>
            {/* end::Content */}

        </div>
    )
}
export  default memo(RegistrationSuccessRedirect);