import config from '../config.json';
import base from "axios";
import {NotyWrap} from "../app/utils";
const TOKEN = localStorage.getItem('token');
export const axios = base.create({
    baseURL:  config.APP_URL
});
interface IErrorObject {
    message: string;
}
axios.defaults.headers.common = {'Authorization': `Bearer ${TOKEN}`};
axios.interceptors.response.use(
    response => response,
    error =>{
        if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
            // return Promise.reject(error);
        }
        if(typeof error.response === 'undefined'){
            NotyWrap('Ошибка на сервере, повторите позже', 'error');
        }else {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            }

            if (error.response.status  === 422) {
                error.response.data.map( (item:IErrorObject) => {
                    NotyWrap(item.message, 'error');
                });
            }
        }
    }
);

export const setToken = (token:string) => {
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};
};

export default axios;