/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {login, register} from '../redux/AuthCRUD'
import {Link, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getLinkToOAuthApiRequest} from '../../../../api/account'
import {localStorageGet, NotyWrap} from "../../../utils";
import {IRegistration} from "../models/AuthRegistration";

const initialValues = {
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: true,
}

type TSocTypeLink = string;

const registrationSchema = Yup.object().shape({

  email: Yup.string()
    .email('Неправильный формат')
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Email поле обязательное'),
  password: Yup.string()
    .min(6, 'Минимум 6 символа')
    .max(50, 'Максимум 50 символов')
    .required('Поле обязательное'),
  changepassword: Yup.string()
    .required('Поле обязательное')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Пароль и подтверждение пароля не совпадают'),
    }),
  acceptTerms: Yup.bool().required('Вы должны принять политику конфидециальности '),
})

export function Registration() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let history = useHistory();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        let newData:IRegistration = {
          email: values.email,
          password: values.password
        }
        let utm_source = localStorageGet('utm_source', null);
        let utm_medium = localStorageGet('utm_medium', null);
        let utm_campaign = localStorageGet('utm_campaign', null);
        let utm_term = localStorageGet('utm_term', null);
        // let referral = localStorageGet('referral', false);
        // if (referral) data.append('referral', referral);

        newData.utm_source = utm_source ? utm_source : '';
        newData.utm_medium = utm_medium ? utm_medium : '';
        newData.utm_campaign = utm_campaign ? utm_campaign : '';
        newData.utm_term = utm_term ? utm_term : '';

        register(newData)
          .then(() => {
            login(values.email,values.password)
                .then((res)=>{
                  const TOKEN = res.data.data.token;
                  setLoading(false)
                  localStorage.setItem('LETSSTORY_TOKEN', TOKEN);
                  dispatch(auth.actions.login(TOKEN));
                  history.push("/");
                })
                .catch(()=>{
                  setLoading(false)
                  setSubmitting(false)
                  setStatus('Ошибка регистрации, повторите позже')
                })

          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Ошибка регистрации, повторите позже')
          })
      }, 1000)
    },
  })

  const handlerLinkSoc = (type: TSocTypeLink) => {
    if (type && !loading) {
      setLoading(true);
      getLinkToOAuthApiRequest(type)
        .then((res) => {
          // console.log(res)
          window.open(res, '_blank')?.focus();
          setLoading(false);
        })
        .catch(() => {
          console.log('error')
          setLoading(false);
          NotyWrap('Ошибка на сервере, повторите позже', 'error');
        })
    }
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Регистрация</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Есть аккаунт?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Войти
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      <button
        type='button'
        className='btn btn-light-primary fw-bolder w-100 mb-2'
        onClick={() => handlerLinkSoc('google')}
      >
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Регистрация через Google
      </button>
      <button
          type='button'
          className='btn btn-light-primary fw-bolder w-100 mb-2'
          onClick={() => handlerLinkSoc('vk')}
      >
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/vk-logo.svg')}
          className='h-20px me-3'
        />
        Регистрация через ВКонтакте
      </button>

      <button
        type='button'
        className='btn btn-light-primary fw-bolder w-100 mb-10'
        onClick={() => handlerLinkSoc('yandex')}
      >
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/yandex-logo.svg')}
          className='h-20px me-3'
        />
        Регистрация через Яндекс
      </button>


      {/* end::Action */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>или</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}


      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            },
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Пароль</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Пароль'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                },
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Подтверждение пароля</label>
        <input
          type='password'
          placeholder='Подтверждение пароля'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            },
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            checked
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            Я согласен{' '}
            <a target="_blank" href='https://letstory.me/user_agreemnet.pdf' className='ms-1 link-primary'>
              Политикой конфиденциальности
            </a>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Зарегистрироваться</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Загрузка...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
