import axios from './index';
import config from "../config.json";
import {encodeGetDataUrl} from "../app/utils";
import {IRegistrationSoc} from "../app/modules/auth/models/AuthRegistrationSoc";

export const sendVerifyOAuthApiRequest = (values:IRegistrationSoc): Promise<string> => {
    return new Promise((resolve, reject): void => {
        axios.get(`${config.APP_URL}/api/${config.VERSION_API}/auths/cb?${encodeGetDataUrl(values)}`).then(response => {
            if (response && 'status' in response && response.data.status === "success") {
                resolve(response.data.data);
            } else {
                reject('Ошибка привязки соцсети');
            }
        });
    });
}

export const getLinkToOAuthApiRequest = (type: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        let data = new FormData();
        data.append('type', type);
        axios.post(`${config.APP_URL}/api/${config.VERSION_API}/auths/get-auth-link`, data).then(response => {
            if (response && 'status' in response && response.data.status === "success") {
                resolve(response.data.data.url);
            } else {
                reject('Ошибка привязки соцсети');
            }
        });
    });
}
