import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import {UserModel} from "../models/UserModel";

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
    const didRequest = useRef(false)
    const dispatch = useDispatch()
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)

    const requestUser = async () => {
        try {
            if (!didRequest.current) {
                const {data: {data: user}} = await getUserByToken()
                dispatch(props.fulfillUser(user))
            }
        } catch (error) {
            console.error(error)
            if (!didRequest.current) {
                dispatch(props.logout())
            }
        } finally {
            setShowSplashScreen(false)
        }

        return () => (didRequest.current = true)
    }

    const autoriseByToken = async (TOKEN:string) =>{
        dispatch(auth.actions.login(TOKEN));
        await requestUser();
        setShowSplashScreen(false);
    }

    // We should request user by authToken before rendering the application
    useEffect(() => {


        const TOKEN = localStorage.getItem('LETSSTORY_TOKEN');
        if (TOKEN && TOKEN !== null) {
            autoriseByToken(TOKEN);
        } else{
            if (accessToken) {
                requestUser();
            } else {
                dispatch(props.logout());
                setShowSplashScreen(false);
                console.log(3)
            }
        }
        // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <LayoutSplashScreen/> : <>{props.children}</>
}

export default connector(AuthInit)
