import axios from 'axios';
import config from "../../../../config.json";
import { ReferralsWidthdrawal } from '../models/ReferralsWidthdrawal';
export const GET_USER_REFERRAL_INFO_URL = `${config.APP_URL}/api/${config.VERSION_API}/referrals/get-info`;
export const SENS_REQUEST_CREATE_WIDTHDRAWAL_URL = `${config.APP_URL}/api/${config.VERSION_API}/referrals/create-withdrawal`;

export function getUserReferralsData() {
    return axios.get(GET_USER_REFERRAL_INFO_URL)
}

export function sendRequestWidthdrawalReferralsData(data:ReferralsWidthdrawal) {
    return axios.post(SENS_REQUEST_CREATE_WIDTHDRAWAL_URL,data)
}


