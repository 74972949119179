import React, {FC, memo, useEffect, useState} from 'react';
import {PageTitle} from "../../../_metronic/layout/core";
import styled from 'styled-components';
import _ from 'lodash';
import {getUserReferralsData, sendRequestWidthdrawalReferralsData} from "../../modules/referrals/redux/ReferralsCRUD";
import {NotyWrap} from "../../utils";
import {ReferralsModel} from "../../modules/referrals/models/ReferralsModel";

const ReferralPage: FC = () => {

    const [data, setData] = useState<ReferralsModel | null>(null);
    const [stateErrorWithdrawal, setStateErrorWithdrawal] = useState<string[] | null>(null);
    const [stateLinkRef, setStateLinkRef] = useState<string | null>(null);
    const [stateValueSum, setStateValueSum] = useState<number | null>(null);
    const [stateOpenBlockWithDrawal, setStateOpenBlockWithDrawal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [stateSelectWidthdrawal, setStateSelectWidthdrawal] = useState<string>('Пополнить счет');

    useEffect(() => {
        getUserReferralsData()
            .then(res => {
                if (res.data?.data) {
                    let tempData = res.data.data;
                    setData(tempData);
                    setStateLinkRef(`https://letstory.me?ref=${tempData.code}`);
                } else {
                    NotyWrap('Ошибка загрузки данных пользователя', 'error');
                }
            }).catch(() => {
            NotyWrap('Ошибка загрузки данных пользователя', 'error');
        })
    }, []);

    //
    //  Copy code ref on clipboard
    //
    const handlerClickCopyCodeRef = () => {
        if (stateLinkRef !== null) {
            navigator.clipboard.writeText(stateLinkRef);
            NotyWrap('Код успешно скопирован', 'success');
        }
    }

    //
    // Handler input sum
    //
    const handlerChangeInputSum = (e: any) => {
        let tempValue = e.target.value;
        let numberValue = +tempValue;
        let readyInsertVal = numberValue;
        if(_.isNaN(numberValue)){
            readyInsertVal = 0;
        }
        setStateValueSum(readyInsertVal);
    }

    //
    // Send request on withdrawal
    //
    const handlerSendRequest = () => {
        setLoading(true);
        if (stateErrorWithdrawal !== null) {
            setStateErrorWithdrawal(null);
        }

        if (stateValueSum !== null) {
            if (stateValueSum > 500) {
                sendRequestWidthdrawalReferralsData({
                    method: 'yandex money',
                    value: 500
                })
                    .then(() => {

                        setLoading(false);
                    })
                    .catch(() => {

                        setLoading(false);
                    });
            } else {
                setStateErrorWithdrawal(['Минимальная сумма вывода 500 рублей']);
            }
        }

    }

    return (
        <ReferralComponent>
            <div className="card mb-5">
                <div className="card-body ">
                    <div className='row gy-5 g-xl-8'>
                        <div className='col-xxl-6'>
                            <h3>Двухуровневая система</h3>
                            <div>
                                Letstory предлагает вам уникальную партнерскую программу: вы будете получать отчисления
                                не только с ваших рефералов, но и с тех пользователей
                                кого ваши рефералы пригласили в сервис.<br/>
                                Вы получите 20% от суммы покупок ваших рефералов + 5% от суммы покупок рефералов ваших
                                рефералов
                            </div>
                        </div>
                        <div className='col-xxl-6'>
                            <h3>Ваша ссылка для приглашения</h3>
                            <div>
                                Используйте эту ссылку, чтобы пригласить пользователя в сервис.
                                <br/>
                                <br/>
                                Запрещено исполльзовть СПАМ для ее распространения.
                                Запрещено регистрироваться повторно по своей ссылке.
                                Мы отслеживаем соблюдение правил и оставляем за собой право отключить пользователя от
                                партнерской программы и обнулить партнерский баланс в случае нарушения правил.
                                <div
                                    className="form fv-plugins-bootstrap5 fv-plugins-framework referral_copy-code-block">
                                    <div className="d-flex">
                                        <input type="text" className="form-control form-control-solid"
                                               placeholder="Реферальная ссылка" name="referral-link"
                                               readOnly={true}
                                               value={stateLinkRef !== null ? stateLinkRef : ""}
                                        />
                                        <button
                                            type="submit"
                                            id="kt_modal_new_target_submit"
                                            className="btn btn-primary"
                                            onClick={handlerClickCopyCodeRef}
                                        >
                                            <span className="indicator-label">Копировать ссылку</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body ">
                    <div className='row gy-5 g-xl-8 mb-19'>
                        <div className='col-xxl-12'>
                            <div className="d-flex flex-wrap justify-content-between row">
                                <div className="col-lg-3 ">
                                    <div
                                        className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                                                 data-kt-countup-value="4500" data-kt-countup-prefix="$">
                                                {data !== null ? data.count_referrals_one : null}
                                            </div>
                                        </div>
                                        <div className="fw-bold fs-6 text-gray-400">Рефералы 1 уровня</div>
                                    </div>
                                </div>
                                <div className="col-lg-3 ">
                                    <div
                                        className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4   mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                                                 data-kt-countup-value="75">
                                                {data !== null ? data.count_referrals_two : null}
                                            </div>
                                        </div>
                                        <div className="fw-bold fs-6 text-gray-400">Рефералы 2 уровня</div>
                                    </div>
                                </div>
                                <div className="col-lg-3 ">
                                    <div
                                        className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4   mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                                                 data-kt-countup-value="60" data-kt-countup-prefix="%">
                                                {data !== null ? data.full_sum : null}
                                            </div>
                                        </div>
                                        <div className="fw-bold fs-6 text-gray-400">Всего заработано</div>
                                    </div>
                                </div>
                                <div className="col-lg-3 ">
                                    <div
                                        className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                                                 data-kt-countup-value="60" data-kt-countup-prefix="%">
                                                {data !== null ? data.balance : null}
                                            </div>
                                        </div>
                                        <div className="fw-bold fs-6 text-gray-400">Партнерский баланс</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="gy-5 g-xl-8">
                        <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7">

                            <span className="svg-icon svg-icon-success me-5">
                                <span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                 height="24" viewBox="0 0 24 24" fill="none">
																<path opacity="0.3"
                                                                      d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                                                      fill="black"></path>
																<path
                                                                    d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                                                    fill="black"></path>
															</svg>
														</span>
													</span>
                            <div className="flex-grow-1 me-2">
                                <span className="text-muted fw-bold d-block">
                                    Заработанные средства можно вывести на Яндекс Деньги  или перевести на основной баланс.<br/>
                                    Минимальная сумма на Яндекс Деньги  - 500 рублей.<br/>
                                    Минимальная сумма на основной баланс - 1 рубль.
                                </span>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={() => {
                                    setStateOpenBlockWithDrawal(!stateOpenBlockWithDrawal);
                                }}
                            >
                                <span className="indicator-label">Перевести</span>
                            </button>

                        </div>

                        <div className={`hide-block  ${stateOpenBlockWithDrawal ? ' active' : ''}`}>
                            <div className='col-lg-12 col-xl-12 mb-2'>
                                <select
                                    className='form-select form-select-solid'
                                    name='layout-builder[layout][header][width]'
                                    value={stateSelectWidthdrawal}
                                    onChange={(e) =>
                                        setStateSelectWidthdrawal(e.target.value)
                                    }
                                >
                                    <option value='menu'>Пополнить счет</option>
                                    <option value='withdrawal'>Вывести деньги</option>
                                </select>
                            </div>
                            <div className="d-flex">
                                <input type="text" className="form-control form-control-solid mr-2"
                                       placeholder="Сумма вывода" name="widthdrawal"
                                       value={stateValueSum !== null ? stateValueSum : ""}
                                       onChange={handlerChangeInputSum}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handlerSendRequest}
                                >
                                    <span className="indicator-label">Отправить</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ReferralComponent>
    )
}

const Referrals: FC = () => {

    return (
        <>
            <PageTitle breadcrumbs={[]}>Партнерская программа</PageTitle>
            <ReferralPage/>
        </>
    )
}
export default memo(Referrals);

const ReferralComponent = styled.div`
  .referral_copy-code-block {
    margin-top: 15px;

    button {
      min-width: 200px;
      margin-left: 15px;
    }
  }

  .hide-block {
    transition: all .4s;
    height: 0;
    overflow: hidden;

    &.active {
      height: 100px;
    }
  }
`;