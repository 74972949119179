import Noty from 'noty';

export const NotyWrap = (text: string, type: "alert" | "success" | "warning" | "error" | "info" | "information" | undefined = 'alert', timeout: number = 3000) => {
    new Noty({
        text: text,
        type: type,
        timeout: timeout
    }).show();
}

/*
* Set url params
*/
export const setUtmParams = (path: any) => {
    const urlParams = new URLSearchParams(path);
    if (urlParams.has('utm_source')) {
        localStorageSet('utm_source', urlParams.get('utm_source'));
    }

    if (urlParams.has('utm_medium')) {
        localStorageSet('utm_medium', urlParams.get('utm_medium'));
    }

    if (urlParams.has('utm_term')) {
        localStorageSet('utm_term', urlParams.get('utm_term'));
    }

    if (urlParams.has('utm_campaign')) {
        localStorageSet('utm_campaign', urlParams.get('utm_campaign'));
    }

    if (urlParams.has('referral')) {
        localStorageSet('referral', urlParams.get('referral'));
    }
}


export const localStorageGet = (key: string, def = null) => {
    let data = localStorage.getItem(key);
    if (data !== null) {
        try {
            data = JSON.parse(data);
        } catch (e) {
            data = def;
        }
    } else {
        data = def;
    }
    return data;
};

export const localStorageSet = (key: string, value: string | null) => {
    localStorage.setItem(key, JSON.stringify(value));
};


export const encodeGetDataUrl = (data:any) => {
    return Object.keys(data).map(function (key) {
        return [key, data[key]].map(encodeURIComponent).join("=");
    }).join("&");
}